import { GlobalActionType } from "../../../constants/globalActionType";

import { GlobalState } from "./GlobalState";
import { IGlobalAction } from "./IGlobalAction";
import { Map } from "immutable";

export const globalReducer = (
  state = Map(new GlobalState()),
  action: IGlobalAction
) => {
  // const { payload } = action;
  switch (action.type) {
    case GlobalActionType.SET_DISABLE_LOGOUT:
      return state.set("disableLogout", action.payload);

    case GlobalActionType.SHOW_MESSAGE_GLOBAL:
      return state.set("message", action.payload);

    case GlobalActionType.HIDE_TOP_LOADING:
      return state.set("showTopLoading", false);

    case GlobalActionType.SHOW_TOP_LOADING:
      return state.set("showTopLoading", true);

    case GlobalActionType.SET_CHANNEL_SIDEBAR_STATUS:
      return state.set("showChannelSidebar", action.payload);

    case GlobalActionType.SET_SIDEBAR_FOR_CHANNEL:
      return state.set("showSidebarForChannel", action.payload);

    case GlobalActionType.TOGGLE_HAMBURGER_MENU:
      return state.set("toggleSidebar", !state.get("toggleSidebar"));
    
    case GlobalActionType.CLOSE_HAMBURGER_MENU:
      return state.set("toggleSidebar", false);

    case GlobalActionType.ERROR_FROM_SERVER:
      return state.set("errorOnServer", !state.get("errorOnServer"));

    case GlobalActionType.SET_CURRENT_VIEW:
      return state.set("currentView", action.searchQuery);

    case GlobalActionType.SET_CURRENT_SLUG:
      return state.set("currentSlug", action.payload);

    case GlobalActionType.SET_POSTS_QUERY:
      return state.set("postsQuery", action.searchQuery);

    case GlobalActionType.REFRESH_SIDEBAR:
      return state.set("refreshSidebar", !state.get("refreshSidebar"));

    case GlobalActionType.GOT_TO_SIDEBAR_CHANNEL:
      return state.set("goToSidebarChannel", action.payload);

    case GlobalActionType.MODAL_IS_OPEN:
      return state.set("isModalActive", !state.get("isModalActive"));

    case GlobalActionType.SHOW_PUBLIC_GROUP_LOGIN:
      return state.set(
        "showPublicLoginPopup",
        !state.get("showPublicLoginPopup")
      );

    case GlobalActionType.SHOW_PUBLIC_GROUP_SIGNUP:
      return state.set(
        "showPublicSignupPopup",
        !state.get("showPublicSignupPopup")
      );

    case GlobalActionType.SET_SELECTED_GROUP:
      return state.set(
      "selectedGroup",
      action.payload
    );

    case GlobalActionType.SET_GROUP_THEME:
      return state.set("theme", action.payload);

    case GlobalActionType.TOGGLE_USER_PROFILE_SLIDER:
      return state.set("userProfileSlider", !state.get("userProfileSlider"));

    case GlobalActionType.SET_SLIDEOUT_PROFILE_DATA:
      return state.set("slideoutProfileData", action.payload);

    case GlobalActionType.OPEN_USER_PROFILE_SLIDER:
      return state.set("userProfileSlider", action.payload);

    case GlobalActionType.CLOSE_USER_PROFILE_SLIDER:
      return state.set("userProfileSlider", action.payload);

    default:
      return state;
  }
};
