// import { push } from "connected-react-router";
import { SocialError } from "../../core/models/common";
import { globalActions } from "./index";
import { login } from "./authorizeActions";
// import { provider } from "../../groupApp";
// import { SocialProviderTypes } from "../../core/socialProviderTypes";
// import { IUserService } from "../../core/services/users";
import { UserService } from "../../api/services/users";
import { AuthorizeService } from "../../api/services/authorize";

export const dbLogin = (email: string, password: string) => {
  return (dispatch: any, getState: any) => {
    return AuthorizeService.getInstance().login(email, password, null, null).then(
      (result: any) => {
        dispatch(login(result));
        // dispatch(push("/home"));
      },
      (error: SocialError) => {
        dispatch(globalActions.showMessage(error.code));
      }
    );
  };
};

// export const userService: IUserService = provider.get<IUserService>(
//   SocialProviderTypes.UserService
// );

export const updatePassword = (userId: number, payload: any) => {
  return (dispatch: any) => {
    return UserService.getInstance()
      .updateUserPassword(userId, payload)
      .then((result: any) => {
        dispatch(globalActions.showMessage(result.message));
        return Promise.resolve(result);
      })
      .catch((err: any) => {
        dispatch(globalActions.showMessage(err.data));
      });
  };
};
