import axios from "axios";
import { handleError, handleResponse, headerify } from "./APIUtils";
import appConfig from "../appConfig";

const databaseInstance = axios.create({ baseURL: appConfig.api.baseUrl });

databaseInstance.defaults.headers.post["Content-Type"] = "application/json, charset=utf-8";
databaseInstance.interceptors.request.use(headerify);
databaseInstance.interceptors.response.use(handleResponse, handleError);

export default databaseInstance;
