import { SocialError } from "../../core/models/common";
import { globalActions, groupActions } from "./index";
// import { provider } from "../../groupApp";
// import { SocialProviderTypes } from "../../core/socialProviderTypes";
// import { IGroupService } from "../../core/services/groups";
import { GroupActionType } from "../../constants/groupActionType";
import { push } from "connected-react-router";
import appConfig from "../../appConfig";
import { GroupService } from "../../api/services/groups";

// export const groupService: IGroupService = provider.get<IGroupService>(
//   SocialProviderTypes.GroupService
// );

export const dbCreate = (
  userId: number,
  name: string,
  description: string,
  status: string,
  slug?: string,
  planId?: any,
  paymentId?: any,
  beta_access_code?: any,
  subdomain?: any,
  coupon_code?: any,
  referral_code?: any
) => {
  return (dispatch: any, getState: any) => {
    return GroupService.getInstance()
      .createGroup(
        userId,
        name,
        description,
        status,
        slug,
        planId,
        paymentId,
        beta_access_code,
        subdomain,
        coupon_code,
        referral_code
      )
      .then(
        (result: any) => {
          if (userId) {
            dispatch(groupActions.dbGetUserGroups(userId));
          }
          if (subdomain) {
            window.location.assign(
              `http://${result.data.slug}.${appConfig.domain.baseDomain}`
            );
          } else {
            dispatch(push(`/group/${result.data.slug}`));
          }
        },
        (error: SocialError) => {
          dispatch(globalActions.showMessage(error.code));
        }
      )
      .catch(error => {
        dispatch(globalActions.showMessage(error.code));
      });
  };
};

export const dbGetAll = (searchQuery?: any) => {
  return (dispatch: any, getState: any) => {
    return GroupService.getInstance().getAllGroups(searchQuery).then(
      (result: any) => {
        dispatch(globalActions.hideTopLoading());
      },
      (error: SocialError) => {
        dispatch(globalActions.showMessage(error.code));
      }
    );
  };
};

export const getUserGroups = (groups: any) => {
  return {
    type: GroupActionType.GET,
    payload: { groups }
  };
};

export const setSidebarLoadingStatus = (state: boolean) => {
  return {
    type: GroupActionType.LOAD,
    state
  };
};

export const dbGetUserGroups = (userId: number, isGroupRemoved?: boolean) => {
  return (dispatch: any, getState: any) => {
    dispatch(setSidebarLoadingStatus(true));
    if (userId) {
      return GroupService.getInstance().getUserGroups(userId).then(
        (result: any) => {
          if (result && result.data) {
            dispatch(getUserGroups(result.data));
            dispatch(setSidebarLoadingStatus(false));
            if (isGroupRemoved) {
              dispatch(push(`/`));
            }
          }
        },
        (error: SocialError) => {
          dispatch(setSidebarLoadingStatus(false));
          dispatch(globalActions.showMessage(error.code));
        }
      );
    }
  };
};

export const dbGetPostData = (groupId: number, postId: number) => {
  return (dispatch: any, getState: any) => {
    console.log('LOG: going to get post data fro api');
    dispatch(setSidebarLoadingStatus(true));
    if (postId) {
      return GroupService.getInstance().getGroupPost(groupId, postId).then(
        (result: any) => {
          if (result) {
            console.log('LOG: post from api returned');
            dispatch(getPostData(result));
            dispatch(setSidebarLoadingStatus(false));
          }
        },
        (error: SocialError) => {
          dispatch(getPostData(null));
          dispatch(setSidebarLoadingStatus(false));
          dispatch(globalActions.showMessage(error && error.code));
        }
      );
    }
  };
};
export const getPostData = (post: any) => {
  return {
    type: GroupActionType.POST,
    payload: post
  };
};

export const dbGroupJoin = (userId: any, groupId: any) => {
  return (dispatch: any, getState: any) => {
    return GroupService.getInstance().joinGroup(userId, groupId).then((result: any) => {
      dispatch(groupActions.dbGetUserGroups(userId));
    });
  };
};

export const dbGroupLeave = (userId: any, groupId: any) => {
  return (dispatch: any, getState: any) => {
    return GroupService.getInstance().leaveGroup(userId, groupId).then((result: any) => {
      dispatch(groupActions.dbGetUserGroups(userId));
    });
  };
};

export const loadedGroup = (group: string) => {
  return {
    type: GroupActionType.SELECTED_GROUP,
    payload: group
  };
};
