export class SocialError extends Error {
  private _isError: Boolean;

  constructor(private status: string, private _data: {}) {
    super();
    this._isError = true;
  }

  public get code(): string {
    return this.status;
  }

  public get data(): any {
    return this._data;
  }

  public get isError(): Boolean {
    return this._isError;
  }
}
