export class GlobalState {
  [key: string]: any;
  public message: object = {};
  public showTopLoading: boolean = false;
  public toggleSidebar: boolean = false;
  public errorOnServer: boolean = true;
  public currentView: string = "";
  public currentSlug: string = "";
  public postsSearchQuery: string = "";
  public showChannelSidebar: boolean = false;
  public showSidebarForChannel: boolean = false;
  public showPublicLoginPopup: boolean = false;
  public showPublicSignupPopup: boolean = false;
  public isModalActive: boolean = false;
  public disableLogout: boolean = false;
}
