import { createStore, applyMiddleware, Store } from "redux"; // compose
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { createBrowserHistory, createMemoryHistory } from "history";
import createSagaMiddleware, { END } from "redux-saga";
import { createLogger } from "redux-logger";
import { rootReducer } from "../store/reducers";
import { fromJS, Map } from "immutable";
// import DevTools from './devTools';
import { routerMiddleware } from "connected-react-router/immutable"; // connectRouter
// replacing redux-offline defaults with immutable* counterparts;
// import {persist, persistAutoRehydrate, offlineStateLens} from 'redux-offline-immutable-config';

// Create a history of your choosing (we're using a browser history in this case)
export const history = typeof window !== 'undefined' ? createBrowserHistory() : createMemoryHistory();

// Logger option for transforming immutable js
const logger = createLogger({
  stateTransformer: (state: Map<string, any>) => {
    return state.toJS();
  }
});

const sagaMiddleware = createSagaMiddleware();
// - initial state
const initialState = {};

// - Config and create store of redux
const composeEnhancers = composeWithDevTools({
  // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
});

let store: Store<any>;

store = createStore(
  rootReducer(history),
  fromJS(initialState),
  composeEnhancers(
    applyMiddleware(logger, thunk, routerMiddleware(history), sagaMiddleware)
  )
);

// tslint:disable-next-line:object-shorthand-properties-first
export default {
  store,
  runSaga: sagaMiddleware.run,
  close: () => store.dispatch(END),
  history
};
