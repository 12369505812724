import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import "reflect-metadata";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { ConnectedRouter } from "connected-react-router/immutable";
import configureStore from "./store/configureStore";
import "./groupApp";
import { Provider } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StripeProvider } from "react-stripe-elements";
import appConfig from "./appConfig";

import TagManager from "react-gtm-module";
import "react-csv-importer/dist/index.css";
import {
  QueryClientProvider,
  QueryClient,
} from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import { clarity } from 'react-microsoft-clarity';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import miscUtils from "./utils/miscUtils";
import Cookie from "js-cookie";

// Lazy load the components
const LivePageContainer = lazy(() => import("./containers/PageBuilder/LivePageContainer"));
const MainComponent = lazy(() => import("./containers/main"));

if(typeof window !== 'undefined') {
  const params = new URLSearchParams(window.location.search);
  const fbclid = params.get('fbclid');
  const _fbc = Cookie.get('_fbc');

  if(fbclid && !_fbc) {
    const version = 'fb';
    const subdomainIndex = miscUtils.getSubdomainIndex(window.location.hostname);
    const creationTime = Date.now();
    const formattedValue = `${version}.${subdomainIndex}.${creationTime}.${fbclid}`;

    Cookie.set('_fbc', formattedValue, { path: "/", domain: window.location.hostname, expires: 90 });
  }
}

// const options = {
//   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//   debug: false, // enable logs
// };

// Initialize Facebook Pixel if running in browser
// if (typeof window !== 'undefined') {
//   import('react-facebook-pixel').then(module => {
//     const ReactPixel = module.default;
//     //@ts-ignore
//     ReactPixel.init('204705047609980', null, options); // Initialize with your Pixel ID
//     ReactPixel.pageView(); // For tracking page views
//   });
// }

// Initialize Tag Manager in production
if (process.env.REACT_APP_ENV === "production") {
  const tagManagerArgs = {
    gtmId: "GTM-5LP5HHB"
  };
  TagManager.initialize(tagManagerArgs);
}

if (process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "staging") {
  Sentry.init({
    dsn: 'https://417f82af114e4ea592b37c4186de7e36@o266270.ingest.us.sentry.io/1547076',
    // @ts-ignore
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
  });
}

// TODO move this to prod area above after testing
if(typeof window !== 'undefined') {
  clarity.init('m4v1fa7c35');
}

// if(window.location.pathname.split("/")[1] !== "pages") {
// }

toast.configure({
  hideProgressBar: true
});

// Initialize Query Client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

// Add scripts conditionally
const addScripts = () => {
  if (typeof window !== 'undefined' && window.location.pathname.split("/")[1] === "pages") {
    return;
  }

  if (typeof document === 'undefined') {
    return;
  }

  // Add your scripts here
  const script1 = document.createElement('script');
  script1.innerHTML = `
  (function(w) {
    w.fpr = w.fpr || function() {
      w.fpr.q = w.fpr.q || [];
      w.fpr.q[arguments[0] == "set" ? "unshift" : "push"](arguments);
    };
  })(window);
  fpr("init", { cid: "85fvobma" });
  fpr("click");
  `;
  document.head.appendChild(script1);

  const script2 = document.createElement('script');
  script2.innerHTML = `
  (function (c, l, a, r, i, t, y) {
    c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
    t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", "m4v1fa7c35");
  `;
  document.head.appendChild(script2);

  const script3 = document.createElement('script');
  script3.async = true;
  script3.src = `https://cdn.tolt.io/tolt.js`;
  script3.setAttribute(`data-tolt`, `d02be954-ef33-49a5-b0b5-94ba374a8cb0`);
  document.head.appendChild(script3);

  const script4 = document.createElement('script');
  script4.async = true;
  script4.src = `https://js.stripe.com/v3/`;
  document.body.appendChild(script4);
};

const Root = () => {
  const [stripe, setStripe] = React.useState<any>(null);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      //@ts-ignore
      if (window.Stripe) {
        //@ts-ignore
        setStripe(window.Stripe(appConfig.stripe.public_key));
      } else {
        const stripeScriptElement = document.querySelector('gp-stripe-js-script');

        if (stripeScriptElement) {
          stripeScriptElement.addEventListener('load', () => {
            // Create Stripe instance once Stripe.js loads
            //@ts-ignore
            setStripe(window.Stripe(appConfig.stripe.public_key));
          });
        }
      }
    }
  }, []);

  if (typeof window !== 'undefined' && window.location.pathname.split("/")[1] === "pages") {
    return (
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<></>}>
            <LivePageContainer />
          </Suspense>
        </QueryClientProvider>
      </HelmetProvider>
    )
  }

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <StripeProvider stripe={stripe}>
          <Provider store={configureStore.store}>
            <ConnectedRouter history={configureStore.history}>
              <Suspense fallback={<></>}>
                <MainComponent />
              </Suspense>
            </ConnectedRouter>
          </Provider>
        </StripeProvider>
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default Root;

// Add scripts after the Root component is defined
addScripts();

// Hydrate the app if running in the browser
if (typeof document !== 'undefined') {
  ReactDOM.hydrate(
    <Root />,
    document.getElementById("app")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
