// enum for channel layouts
export enum CHANNEL_LAYOUT_TYPES {
  CARD = "card", 
  LIST = "list", 
  FEED = "feed"
}

// enum for category types
export enum CATEGORY_TYPE {
  COURSE = "course", 
  LIBRARY_CONTENT = "library-content", 
  CHANNEL = "channel"
}

// Content status types
export enum CONTENT_STATUS_TYPES {
  PUBLISHED = "published", 
  DRAFT = "draft"
}

export enum LIBRARY_VIEWS {
  ALL_CONTENTS = "all_contents",
  MY_CONTETNS = "my_contents"
}

export enum SUBSCRIPTION_PLAN_INTERVALS {
  ONE_TIME = "one_time"
}

export enum USER_SUBSCRIPTION_STATUS {
  CANCELED = "canceled", 
  PAST_DUE = "past_due"
}
