import appConfig from "../appConfig";

export function getlocalStorageValue(key: string) {
    const value = localStorage.getItem(key);
    if (!value) return null;
    try {
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
}

export function setlocalStorageValue(key: string, value: any) {
    const options: any = {
        path: "/",
        expires: 14
    };

    if (
        window.location.host.includes('group.app') ||
        window.location.host.includes('group.localhost')
    ) {
        options['domain'] = appConfig.domain.baseDomain;
    } else {
        options['domain'] = window.location.hostname;
    }

    localStorage.setItem(key, JSON.stringify({ value, options }));
}

export function removelocalStorageValue(key: string) {
    localStorage.removeItem(key);
}