import { environment as prod } from "./environment.prod";
import { environment as dev } from "./environment.dev";
import { environment as stg } from "./environment.stg";

const config = {
  // Add common config values here..
  reservedSubdomains: ["www", "group", "stg", "my", "staging"]
};

let configEnv;
switch (process.env.REACT_APP_ENV) {
  case "production": {
    configEnv = prod;
    break;
  }
  case "staging": {
    configEnv = stg;
    break;
  }
  default: {
    configEnv = dev;
    break;
  }
}

const appConfig = {
  ...config,
  ...configEnv
};

export default appConfig;
