import { isBefore, isEqual, isToday } from "date-fns";
import moment from "moment-timezone";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const BACKEND_DATE_FORMATTING: string = "YYYY-MM-DD HH:mm:00";

function filterPassedTime(time: any, date: any, communityTimezone: any) {
  const now = moment.tz(new Date(), communityTimezone).utc().toDate();
  const convertedDate = moment.tz(date, communityTimezone).utc().toDate();

  if (!convertedDate) {
    return true;
  } else if (!isToday(convertedDate) && !isBefore(time, now)) {
    return true;
  } else if (isBefore(time, now)) {
    return false;
  }

  return now.getTime() < time.getTime();
};

function filterEndTime(time: any, start_time: any) {
  return isBefore(start_time, time);
};

function setSelectedStartDate(start_date: any, end_date: any) {
  return start_date;
}

function getSelectedDate(start_date: any, end_date: any) {
  if (!start_date && end_date) {
    return end_date;
  }

  if (start_date && !end_date) {
    return new Date(start_date);
  }

  if (start_date && (isBefore(end_date, start_date) || isEqual(start_date, end_date))) {
    return new Date(start_date);
  }

  return end_date;
}

function getSelectedEndTime(start_time: any, end_time: any) {
  if (start_time && (isBefore(end_time, start_time) || isEqual(start_time, end_time))) {
    return moment.utc(start_time).add(30, 'minutes').format(BACKEND_DATE_FORMATTING)
  }

  return end_time;
}

function getRoundedDate(minutes: any, date = new Date()) {

  let ms = 1000 * 60 * minutes; // convert minutes to ms
  let roundedDate = new Date(Math.round(date.getTime() / ms) * ms);

  return roundedDate
}

function getDateFromDatestring(dateString: any) {
  var t = dateString.split(/[- :]/);

  return new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
}

function isAttendingEvent(rsvps: any, userId: any) {
  const filterAttendees = rsvps.filter((attendee: any, index: any) => attendee.user_id === userId);

  return filterAttendees.length > 0;
}

function showStartDateYear(start_date: string, end_date: string) {
  const now = moment.utc();

  return (
    !moment.utc(start_date).isSame(moment.utc(end_date), 'year') ||
    !now.isSame(moment.utc(start_date), 'year')
  )
}

function showEndDateYear(end_date: string) {
  const now = moment.utc();

  return (
    !now.isSame(moment.utc(end_date), 'year')
  )
}

function eventIsInSameDay(event: any) {
  return dayjs.utc(event.end_time).local().isSame(dayjs.utc(event.start_time).local(), 'day');
}

export default {
  filterPassedTime,
  filterEndTime,
  setSelectedStartDate,
  getSelectedDate,
  getSelectedEndTime,
  getRoundedDate,
  getDateFromDatestring,
  isAttendingEvent,
  showStartDateYear,
  showEndDateYear,
  eventIsInSameDay,
};
