import moment from "moment/moment";
import appConfig from "../appConfig";
import { RoleIds } from "../containers/teamsContainer/domain";
import { toast, ToastType } from "react-toastify";
import { CourseService } from "../api/services/course";
import { setCookieValue } from "./cookieStorage";
import momentTimezone from "moment-timezone";
import getSymbolFromCurrency from "currency-symbol-map";
import eventsUtils from "../containers/events/eventsUtils";
import { SSOMethod } from "../containers/IntegrationsContainer/SingleSignOn/domain";
import { ISizeValue, TSize } from "types";
import { SUBSCRIPTION_PLAN_INTERVALS, USER_SUBSCRIPTION_STATUS } from "../enums";
import { setlocalStorageValue } from "./localStorage";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { BunnyLibraryTypes } from "./domain";
import { ContentState, convertFromHTML } from 'draft-js';

let heic2any: any;
if (typeof window !== "undefined") {
  import("heic2any")
    .then((module) => {
      heic2any = module.default;
    })
    .catch((error) => {
      console.error("Error loading heic2any:", error);
    });
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(isBetween);

const logger = (title: string, ...data: any[]) => {
  const randomColor = getRandomColor();

  window.console.log(
    `\n\n%c =======  ${title} ======= %c${moment().format("HH:mm:ss SSS")} \n`,
    `color:${randomColor};font-size:15`,
    `color:${getRandomColor()};font-size:15`
  );
  window.console.log(``);
  window.console.log(`    `, data);
  window.console.log(`\n =========================================`);
};

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const updateObject = (oldObject: any, updatedProperties: any) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

const getStateSlice = (state: any) => state.toJS().locale;

const getNameInitials = (name: any = "") => {
  let abbr = "";
  let str = name || "";
  str = str.split(" ");
  for (let i = 0; i < str.length; i++) {
    abbr += str[i].substr(0, 1);
  }
  if (abbr) {
    if (abbr[1]) {
      return abbr[0].toUpperCase() + abbr[1].toUpperCase();
    }
    return abbr[0].toUpperCase();
  }
};

const getColorInitial = (name: any = "") => {
  if (name && name.length > 1) {
    let abbr = "";
    let str = name || "";
    str = str.split(" ");
    for (let i = 0; i < str.length; i++) {
      abbr += str[i].substr(0, 1);
    }

    return abbr[0].toUpperCase();
  }
};

const convertGroupLink = (link: any = "") => {
  let groupLink = link.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "") || "";
  groupLink = groupLink.split("/")[0];

  return groupLink;
};

const supportVideoFormats = (file: any) => {
  const extension = file.name.slice(-3);
  return file.type !== "video/mp4"
    && file.type !== "video/mov"
    && file.type !== "video/avi"
    && file.type !== "video/x-flv"
    && file.type !== "video/quicktime"
    && file.type !== "video/webm"
    && extension !== "flv";
};

const isAdmin = (userMembership: any) => {
  return userMembership !== null && userMembership !== undefined && (userMembership.role && userMembership.role.id === RoleIds.Admin || userMembership.role && userMembership.role.id === RoleIds.Owner);
};

const isOwner = (userMembership: any) => {
  return userMembership !== null && userMembership !== undefined && userMembership.role && userMembership.role.name === "owner";
};

const isModerator = (userMembership: any) => {
  return userMembership !== null && userMembership !== undefined && userMembership.role && userMembership.role.id === RoleIds.Moderator;
};

const isTeamMember = (userMembership: any) => {
  return isAdmin(userMembership) || isModerator(userMembership);
};

const isGroupMember = (group: any) => {
  return group && isActiveCommunityMember(group.user_membership);
};

function getCourseButtonActionText(group: any, content: any, userMembership: any) {

  const showExpiredCTA = () => {
    // switch(content.access) {
    //   case "free":
    //     return false;
    //   case "paid":
    //     return (
    //       userMembership &&
    //       userMembership.active_subscription &&
    //       userMembership.active_subscription.plan &&
    //       !userMembership.active_subscription.plan.price
    //     )
    //   case "priced":
    //     return true;
    //   case "plan":
    //       return !userIsOnResourcePlan(userMembership, content.authorized_plans);
    //   case "segment":
    //     return !userIsOnResourseSegment(userMembership, content.authorized_segments);
    //   default:
    //       return false;
    // }

    return (
      content &&
      content.active_enrolment &&
      content.active_enrolment.expired_at &&
      !isTeamMember(userMembership)
    )
  }

  if (
    // content &&
    // content.active_enrolment &&
    // content.active_enrolment.expired_at &&
    // !isTeamMember(userMembership) &&
    // !showExpiredCTA()
    showExpiredCTA()
  ) {
    return "Expired";
  }


  if (!((content.active_enrolment) && !(content.active_enrolment && content.active_enrolment.expired_at)) && content.access === "plan") {

    if (!userMembership) {
      return "Enroll in Course";
    }

    if (isTeamMember(userMembership)) {
      return "Start";
    }

    if (userMembership && userMembership.active_subscription) {
      const plan = content.authorized_plans.find((plan: any) => {
        return plan.id === userMembership.active_subscription.plan.id;
      });
      return (plan || isTeamMember(userMembership)) ? "Start" : "Upgrade";
    }

    return "Upgrade";
  }

  if (content.access === "paid" && !userMembership && !content.active_enrolment) {
    return "Enroll in Course";
  }

  if (content.access === "paid" && (userMembership && !((content.active_enrolment) && !(content.active_enrolment && content.active_enrolment.expired_at)) && (!userMembership.active_subscription || userMembership.active_subscription.plan.price === 0) && !isTeamMember(userMembership))) {
    return "Upgrade";
  }

  if (!((content.active_enrolment) && !(content.active_enrolment && content.active_enrolment.expired_at)) && content.access !== "priced") {
    if (!userMembership && content.access !== "free") {
      return "Enroll in Course";
    }
    return "Start";
  }

  if (
    !((content.active_enrolment) &&
      !(content.active_enrolment && content.active_enrolment.expired_at)) &&
    content.access === "priced" && !isTeamMember(userMembership)
  ) {
    return 'Enroll';
  }

  if (!((content.active_enrolment) && !(content.active_enrolment && content.active_enrolment.expired_at)) && content.access === "priced" && isTeamMember(userMembership)) {
    return "Start";
  }

  let contentsCount = isTeamMember(userMembership) ? content.a_lessons_count : content.lessons_count;

  return contentsCount === content.completed_contents_count ? "View" : "Continue";
}

function getCourseButtonAction(actionText: string, content: any, history: any, userId: number, userMembership: any, openLockScreen: any) {

  if (!userId) {
    if (content.lock_screen_active) {
      openLockScreen();
      return;
    }
    if (actionText.startsWith("Enroll")) {
      if (content.access === "paid") {
        setCookieValue("", true);
      }
      if (
        content.access === "priced" &&
        content.price &&
        content.price.options &&
        content.price.options.length === 1
      ) {
        history.push(`/courses/${content.slug}/purchase?payment_option=${content.price.options[0].slug}`);
        return;
      }
      history.push(`/courses/${content.slug}/purchase`);
      return;
    }

    if (actionText === "Start" && content.access === "free") {
      setlocalStorageValue("next_link", window.location.pathname);
    }

    if (
      content.access === "plan" &&
      content.authorized_plans.filter((plan: any) => !plan.archived_at).length === 0
    ) {
      history.push("/plan-disabled");
      return;
    }

    if (
      content.access === "plan" &&
      content.authorized_plans.filter((plan: any) => !plan.archived_at).length === 1
    ) {
      history.push(`/plan-subscription/checkout?plan=${content.authorized_plans.filter((plan: any) => !plan.archived_at)[0].uuid}&type=courses&id=${content.slug}`);
      return;
    }

    if (
      content.access === "plan" &&
      content.authorized_plans.filter((plan: any) => !plan.archived_at).length > 1
    ) {
      history.push(`/plan-subscription?type=courses&id=${content.slug}`);
      return;
    }

    history.push("/plan-subscription");
  }

  if (userId) {
    if (actionText === "Expired" && content.access === "priced") {
      if (
        content.price &&
        content.price.options &&
        content.price.options.length === 1
      ) {
        history.push(`/courses/${content.slug}/purchase?payment_option=${content.price.options[0].slug}`);
        return;
      }
      history.push(`/courses/${content.slug}/purchase`);
      return;
    }
    if (actionText === "Expired") {
      toast('Access expired', {
        type: ToastType.ERROR,
        autoClose: 2000,
      });
      return;
    }

    if (actionText === "Start" && (content.access === "free" || content.access === "plan" || content.access === "segment")) {
      startCourse(content, history);
      return;
    }

    if (actionText === "Restart") {
      startCourse(content, history);
      return;
    }

    if ((actionText === "Start" || actionText === "Upgrade" || actionText === "Expired" || actionText === "Enroll in Course") && content.access === "paid" && (!userMembership || !userMembership.active_subscription || userMembership.active_subscription.plan.price === 0) && !isTeamMember(userMembership)) {
      if (content.lock_screen_active) {
        openLockScreen();
        return;
      }
      history.push("/plan-subscription");
      return;
    }

    if (actionText === "Start" && content.access === "paid" && ((userMembership && userMembership.active_subscription && userMembership.active_subscription.plan.price > 0) || isTeamMember(userMembership))) {
      startCourse(content, history);
      return;
    }

    if (actionText.startsWith("Enroll") || (actionText === "Expired" && content.access === "priced")) {
      if (
        content.access === "priced" &&
        content.price &&
        content.price.options &&
        content.price.options.length === 1
      ) {
        history.push(`/courses/${content.slug}/purchase?payment_option=${content.price.options[0].slug}`);
        return;
      }
      history.push(`/courses/${content.slug}/purchase`);
      return;
    }

    if (actionText === "Start" && content.access === "priced") {
      startCourse(content, history);
      return;
    }

    if (actionText === "Upgrade" || actionText === "Expired") {
      if (content.lock_screen_active) {
        openLockScreen();
        return;
      }

      if (
        content.access === "plan" &&
        content.authorized_plans &&
        content.authorized_plans.filter((plan: any) => !plan.archived_at).length === 0
      ) {
        // toast("You do not have permission to access this course", {
        //   type: ToastType.ERROR,
        //   autoClose: 2000,
        // });
        window.location.replace("/plan-disabled");
        return;
      }
      if (
        content.access === "plan" &&
        content.authorized_plans &&
        content.authorized_plans.filter((plan: any) => !plan.archived_at).length === 1
      ) {
        const coursePlans = content.authorized_plans.filter((plan: any) => !plan.archived_at)
        window.location.replace(`/plan-subscription/checkout?plan=${coursePlans[0].uuid}&type=courses&id=${content.slug}`);
        return;
      }

      if (
        content.access === "plan" &&
        content.authorized_plans &&
        content.authorized_plans.filter((plan: any) => !plan.archived_at).length > 1
      ) {
        window.location.replace(`/plan-subscription?type=courses&id=${content.slug}`);
        return;
      }
      history.push("/plan-subscription");
      return;
    }
  }

  if (actionText === "Continue" || actionText === "View") {
    const contentId = (content.active_enrolment.last_content && content.lessons && content.lessons.includes(content.active_enrolment.last_content)) ? content.active_enrolment.last_content : content.lessons[0];
    history.push(`/courses/${content.slug}/lectures/${contentId}`);
    return;
  }
}

function startCourse(course: any, history: any) {
  if (course && course.contents_count === 0) {
    toast("This course doesn't have any content created.", {
      type: ToastType.ERROR,
      autoClose: 3000
    });
    return;
  }

  CourseService.getInstance()
    .startCourse(course.id)
    .then((response: any) => {
      history.push(`/courses/${course.slug}/lectures/${course.lessons[0]}`);
    });
}

function getAccessLabel(content: any, userMembership?: any, group?: any) {
  if (content.active_access && userMembership && !isTeamMember(userMembership)) {
    return "Viewed";
  }
  if (content.active_enrolment_id &&
    userMembership &&
    !isTeamMember(userMembership)
  ) {
    return "Enrolled";
  }
  if (content.access === "priced") {
    return `${getCurrencyPrefix(content.currency)}${getCurrencySymbol(getResourceCurrency(content.currency, group && group.currency))}${content.price}`;
  }
  if (content.access === "paid") {
    return "Paid members";
  }
  return content.access;
}

function getCurrencyPrefix(currency: string): string {
  if (currency === "aud") {
    return "A";
  }
  if (currency === "cad") {
    return "C";
  }

  return "";
}

function sanitizeLibraryContentSlug(slug: string): string {
  return slug.replace(/\s+/g, '-').toLowerCase();
}

function sanitizeQuillValue(value: string) {
  if (value) {
    return value.replace(/<p class="ql-align-justify"><br><\/p>/g, "")
      .replace(/<p><br><\/p>/g, "");
  }
  return "";
}


const getRedirectBaseUrl = (group: any) => {
  let url = "";

  if (group) {
    if (group && group.custom_domain && group.custom_domain_status === "active" && appConfig.env !== "dev") {
      url = `http://${group.custom_domain}`;
    } else {
      url = `${appConfig.domain.protocol}://${group.subdomain}.${appConfig.domain.baseDomain}`;
    }
  } else {
    url = `${appConfig.domain.protocol}://${appConfig.domain.baseDomain}`;
  }

  if (appConfig.domain.port) {
    url = url + `:${appConfig.domain.port}`;
  }

  return url;

};

const getBaseDomain = () => {
  let domain = appConfig.domain.baseDomain;
  if (appConfig.env === "prod") {
    domain = `my.${domain}`;
  }
  domain = `${appConfig.domain.protocol}://${domain}`;
  if (appConfig.domain.port) {
    domain = `${domain}:${appConfig.domain.port}`;
  }

  return domain;
};

const getGroupSlugChannel = (path: string) => {
  const groupSlug = path.includes("/group/")
    ? (path = path.replace("/group/", ""))
    : path.replace("/channel/", "");
  const slug = groupSlug.split("/")[0];
  let channel: any = groupSlug.split("/")[1];

  if (channel) {
    channel = +channel;
  }
  return { slug, channel };
};

const convertWindowsLink = (link: any = "") => {
  let historyLink = link.replace(/^(?:\/group\/?:\/\/)?/i, "") || "";
  historyLink = historyLink.replace("/group/", "");
  return historyLink;
};

const getUrlFromString = (content: any = "") => {
  const urlRegex = /(http|https):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?/;
  const matchedUrl = content.match(urlRegex);
  return matchedUrl;
};

const getDomainFromUrl = (url: string) => {
  const matches = url.match(/^https?:\/\/([^?#]+)(?:[?#]|$)/i);
  return matches && matches[1];
};

function stringCapitalisation(name: string) {
  if (!name) {
    return '';
  }
  return `${name[0].toUpperCase()}${name.slice(1).toLocaleLowerCase()}`;
}

function getChannelLinksColor(group: any, channel: any, selectedChannel: any, theme: any) {
  if (channel && selectedChannel && theme && theme.custom) {
    const rgbValue: any = hexToRgb(theme.custom.primaryColor);
    if (
      selectedChannel === channel.slug &&
      theme.custom &&
      !(
        group &&
        group.home_page_config &&
        group.home_page_config.status &&
        group.default_channel &&
        group.default_channel.slug === selectedChannel &&
        window.location.pathname === "/"
      ) &&
      !window.location.pathname.split("/").includes("library") &&
      !window.location.pathname.split("/").includes("members") &&
      !window.location.pathname.split("/").includes("courses")
    ) {
      return {
        backgroundColor: `rgba(${rgbValue.r}, ${rgbValue.g}, ${rgbValue.b}, .1)`,
        // borderRadius: "3px",
        // color: "black",
        // fontSize: "13px"
      };
    }
  }
  return {};
}

function getBlockButtonStyle(theme: any, onlyBackground?: boolean) {
  const style: { [k: string]: any } = {};
  const whiteCommunityTheme = theme && theme.custom && theme.custom.primaryColor === '#ffffff'

  if (theme && theme.custom) {
    style.color = !onlyBackground ? theme.custom.fontColor : '#ffffff';
    style.border = "none";
    if (theme.custom.primaryColor) style.backgroundColor = theme.custom.primaryColor;
    if (whiteCommunityTheme) {
      style.color = '#000';
      style.border = '1px solid #000';
    }
  }
  return style;
}

function getBlockInverseButtonStyle(theme: any, hover: boolean = false) {
  const style: { [k: string]: any } = {};
  if (theme && theme.custom && theme.custom.primaryColor) {
    style.color = theme.custom.fontColor;
    style.border = `1px solid ${theme.custom.fontColor}`;
    if ((hover || !hover)) {
      style.backgroundColor = theme.custom.primaryColor;
    }
  }
  return style;
}

function getTextButtonStyle(theme: any) {
  const style: { [k: string]: any } = {};
  if (theme && theme.custom && theme.custom.primaryColor) {
    style.color = theme.custom.primaryColor;
  }
  return style;
}

function getOutlineButtonStyle(theme: any, hover: boolean = false, disabled: boolean = false) {
  if (theme && theme.custom && theme.custom.primaryColor) {
    if (hover && !disabled) {
      return {
        color: theme.custom.fontColor,
        backgroundColor: theme.custom.primaryColor,
        border: `1px solid ${theme.custom.fontColor}`
      };
    }
    if (hover && disabled) {
      return {
        color: theme.custom.primaryColor,
        backgroundColor: "white",
        border: `1px solid ${theme.custom.primaryColor}`
      };
    }
    return { color: theme.custom.primaryColor, border: `1px solid ${theme.custom.primaryColor}` };
  }
  return {};
}

function getCoursePriceLabelStyle(theme: any) {
  const style: { [k: string]: any } = {};
  if (theme && theme.custom && theme.custom.primaryColor) {
    style.border = `1px solid ${theme.custom.primaryColor}`;
    style.backgroundColor = "white";
    style.fontWeight = "bold";
    style.color = theme.custom.primaryColor;
  }
  return style;
}

function customThemeSet(theme: any) {
  return theme && theme.custom && theme.custom.primaryColor && theme.custom.primaryColor !== '#ffffff';
}

function secondsToContentDuration(secondsInput: any, simplified?: boolean) {
  if (!secondsInput) {
    return "";
  }
  const sec_num = parseInt(secondsInput);
  const hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);
  let duration = "";
  let timeName = simplified ? "m" : "minutes";

  if (hours > 0) {
    duration += `${hours}:`;
    timeName = simplified ? "h" : "hours";
  }

  if (simplified) {
    duration += `${minutes}:${seconds}${timeName}`;
  } else {
    duration += `${minutes}:${seconds} ${timeName}`;
  }

  return duration;
}

function formatDuration(duration: any) {
  const splited = duration.split(":");
  if (parseInt(splited[0]) !== 0 && parseInt(splited[1]) === 0) {
    return `${parseInt(splited[0])}h`;
  }
  if (parseInt(splited[0]) !== 0 && parseInt(splited[1]) !== 0) {
    return `${parseInt(splited[0])}h ${parseInt(splited[1])}m`;
  }
  if (parseInt(splited[0]) === 0 && parseInt(splited[1]) !== 0) {
    return `${parseInt(splited[1])}m`;
  }
  if (
    parseInt(splited[0]) === 0 &&
    parseInt(splited[1]) === 0 &&
    parseInt(splited[2]) !== 0
  ) {
    return `${parseInt(splited[2])}s`;
  }
  if (
    parseInt(splited[0]) === 0 &&
    parseInt(splited[1]) === 0 &&
    parseInt(splited[2]) === 0
  ) {
    return `0m`;
  }
}

function formatSecondsDuration(seconds: number) {
  const h = Math.floor(seconds / 3600); // Calculate hours
  const m = Math.floor((seconds % 3600) / 60); // Calculate remaining minutes
  const s = seconds % 60; // Calculate remaining seconds

  if (h > 0) {
    return `${h}:${m.toString().padStart(2, '0')} h`; // Format as hours and minutes
  } else if (m > 0) {
    return `${m}:${s.toString().padStart(2, '0')} min`; // Format as minutes and seconds
  } else {
    return `${s} sec`; // Format as seconds
  }
}

function prepareGroupPlans(plans: any) {
  let _tempPlans: any = [];
  const filteredPlans = plans.filter(function (plan: any) {
    return !plan.archived_at;
  });

  _tempPlans = filteredPlans.map(function (plan: any) {
    if (plan.name === "one-time") {
      return { ...plan, rank: 1 };
    }
    if (plan.name === "yearly") {
      return { ...plan, rank: 2 };
    }
    if (plan.name === "quarterly") {
      return { ...plan, rank: 3 };
    }
    if (plan.name === "monthly") {
      return { ...plan, rank: 4 };
    }
    if (plan.name === "free") {
      return { ...plan, rank: 5 };
    }
    if (plan.name === "Daily") {
      return { ...plan, rank: 6 };
    }
    return null;
  });

  _tempPlans.sort((a: any, b: any) => (a.rank > b.rank ? 1 : -1));
  return _tempPlans;
}

function formatGroupPlans(plans: any) {
  const filteredPlans = plans.filter(function (plan: any) {
    return !plan.archived_at;
  });

  filteredPlans.sort((a: any, b: any) => (a.rank > b.rank ? 1 : -1));
  return filteredPlans;
}

function filterNumbers(data: any) {
  return data.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
}

function blobToFile(compressedFile: any, originalFile: any) {
  return new File([compressedFile], originalFile.name);
}

function getBillingFrequency(plan: any) {
  if (plan.interval === "one_time") return null;
  if (plan.interval === "month" && plan.interval_count === 3) {
    return "/quarterly";
  }
  if (plan.interval === "month") return "/monthly";
  if (plan.interval === "year") return "/yearly";
}

function getPlanInterval(plan: any) {
  let plan_interval = "";

  if (plan.interval === "one_time") {
    plan_interval = " / one-time";
  } else if (plan.interval === null && plan.interval_count === null && plan.price === 0) {
    plan_interval = " / month";
  } else {
    if (plan.interval === " month" && plan.interval_count === 3) {
      plan_interval = " /quarter";
    } else if (plan.interval_count === 1) {
      plan_interval = " / " + plan.interval;
    } else {
      plan_interval = " / every " + plan.interval_count + " " + plan.interval + "s";
    }
  }

  return plan_interval;
}

function getPlanIntervally(plan: any) {
  let plan_interval = "";

  if (plan.interval === "one_time") {
    plan_interval = " one-time";
  } else if (plan.interval === null && plan.interval_count === null && plan.price === 0) {
    plan_interval = " monthly";
  } else {
    if (plan.interval === "month" && plan.interval_count === 1) {
      plan_interval = " monthly";
    } else if (plan.interval === "month" && plan.interval_count === 3) {
      plan_interval = " quarterly";
    } else if (plan.interval === "year") {
      plan_interval = " yearly";
    } else if (plan.interval_count === 1) {
      plan_interval = " per " + plan.interval;
    } else {
      plan_interval = " every " + plan.interval_count + " " + plan.interval + "s";
    }
  }

  return plan_interval;
}

function getPlanCycle(plan: any) {
  let plan_interval = "";

  if (plan.interval === "one_time") {
    plan_interval = "one-time";
  } else if (plan.interval === null && plan.interval_count === null && plan.price === 0) {
    plan_interval = "every month";
  } else {
    if (plan.interval === " month" && plan.interval_count === 3) {
      plan_interval = "every quarter";
    } else if (plan.interval_count === 1) {
      plan_interval = " / " + plan.interval;
    } else {
      plan_interval = "every " + plan.interval_count + " " + plan.interval + "s";
    }
  }

  return plan_interval;
}

function toTitleCase(str: any) {
  if (str) {
    return str.replace(
      /\w\S*/g,
      function (txt: any) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }
  return "";
}

function toLowerCase(str: any) {
  let lcString = str.toLowerCase();
  return lcString;
}

function mBToGb(sizeInMB: any, decimals = 2) {
  var sizeInGB = (sizeInMB / (1000)).toFixed(decimals) + "GB";

  return sizeInGB;
}

function getDateDiffInDays(date: any) {
  const tz = momentTimezone.tz.guess();
  const today = momentTimezone.tz(moment(), tz);
  const _date = momentTimezone.tz(date, tz);

  const days = _date.diff(today, "days");
  return days;
}

function getDateDiffInHours(date: any) {
  const tz = momentTimezone.tz.guess();
  const today = momentTimezone.tz(moment(), tz);
  const _date = momentTimezone.tz(date, tz);

  const hours = _date.diff(today, "hours");
  return hours;
}

function isTrialPast(group: any) {
  const tz = momentTimezone.tz.guess();
  const today = momentTimezone.tz(moment(), tz);
  const trialDate = momentTimezone.tz(group.trial_ends_at, tz);

  const dateDiff = trialDate.diff(today);
  if (dateDiff < 0 || !group.active_subscription) {
    return true;
  }
  return false;
}

function getAvailableCurrencies() {
  return [
    {
      name: "US Dollar",
      value: "USD"
    },
    {
      name: "Euro",
      value: "EUR"
    },
    {
      name: "Pound Sterling",
      value: "GBP"
    },
    {
      name: "Australian Dollar",
      value: "AUD"
    },
    {
      name: "Canadian Dollar",
      value: "CAD"
    }
  ];
}

function getCurrencySymbol(currency: any) {
  let currencySymbol: any;
  if (currency && currency !== "NULL" && currency !== "null") {
    currencySymbol = getSymbolFromCurrency(currency);
    if (currencySymbol) {
      return currencySymbol;
    } else {
      return currency.toUpperCase();
    }
  }

  return "$";
}

function getResourceCurrency(currency: any, groupCurrency: any) {
  if (currency && currency !== "NULL" && currency !== "null") {
    return currency.toUpperCase();
  }

  if (groupCurrency && groupCurrency !== "NULL") {
    return groupCurrency.toUpperCase();
  }

  return "USD";
}

function getTimeFromDate(date: any, formattingStyle: any) {
  if (date) {
    // return moment.utc(moment(date)).format(formattingStyle);
    return moment(moment(date)).utc().format(formattingStyle);
  }
  return "";
}

function getFormattedDateFromDate(date: any, formattingStyle: any) {

  if (date) {
    // return moment.utc(moment(date)).format(formattingStyle);
    return moment(moment(date)).utc().format(formattingStyle);
  }
  return "";
}

function getDateFromString(dateString: any, UTC?: boolean) {
  if (dateString) {
    if (UTC) {
      return new Date(dateString + " UTC");
    } else {
      return new Date(dateString);
    }
  }
  return null;
}

function getTimezoneDateFromString(dateString: any, timezone?: any) {
  if (dateString) {
    return new Date(dateString + `${timezone}`);
  }
  return null;
}

function getGroupPlan(group: any) {
  if (group && group.active_subscription && group.active_subscription.plan) {
    return group.active_subscription.plan;
  }
  return {};
}

function isGroupOnPlan(group: any) {
  if (group && group.active_subscription) {
    return true;
  }
  return false;
}

function isGroupOnFreePlan(group: any) {
  const plan = getGroupPlan(group);
  if (plan && plan.price === 0) {
    return true;
  }
  return false;
}

function updateArrayObject(changedObject: any, arrayItems: any, changedAttributes: any) {

  arrayItems.forEach((_object: any, index: any) => {
    if (_object.id === changedObject.id) {
      changedAttributes.forEach((attribute: any, idx: any) => {
        arrayItems[index][attribute] = changedObject[attribute];
      });
    }
  });

  return arrayItems;
}

function getDateInCurrentTimezone(date = new Date()) {
  const tz = momentTimezone.tz.guess();
  // const dateInUtc = moment.utc(moment(date));

  // return momentTimezone.tz(dateInUtc, tz);
  return momentTimezone(date, "YYYY-MM-DD HH:mm:ss").tz(tz);
}

function getDateInGivenTimezone(timezone: any, date?: any) {
  // const dateInUtc = moment.utc(moment(date));

  // return momentTimezone.tz(dateInUtc, timezone);
  // return momentTimezone(date, "YYYY-MM-DD HH:mm:ss A zz").tz(timezone);
  return momentTimezone(date, "YYYY-MM-DD HH:mm:ss").tz(timezone);
}

function getUserTimezone(abbreviation?: any) {
  if (abbreviation) {
    return moment().tz(moment.tz.guess()).format("z");
  }

  return momentTimezone.tz.guess();
}

function getTimezoneAbbreviation(timezone: any) {
  if (timezone) {
    return moment().tz(timezone).format("z");
  }
  return "";
}

function getUserTimezoneAbbreviation(timezone?: string) {
  let targetTimezone = timezone ? timezone : moment.tz.guess();
  const abbreviation = moment().tz(targetTimezone).zoneAbbr();

  // Check if the abbreviation consists only of letters
  if (/^[A-Za-z]+$/.test(abbreviation)) {
    return abbreviation;
  } else {
    return 'GMT';
  }
}

function getUserPlan(user: any) {
  if (user && user.active_subscription && user.active_subscription.plan) {
    return user.active_subscription.plan;
  }
  return {};
}

function getCommunityTimezone(group: any) {
  if (group) {
    if (group.timezone) {
      return group.timezone.value;
    } else {
      return getUserTimezone();
    }
  }
}

function getDateObjectFromGivenTimezone(dateObject: any, timezone: any, dateString?: any) {
  if (dateObject) {
    return eventsUtils.getDateFromDatestring(dateObject.toLocaleString("en-US", { timeZone: timezone }));
  }

  if (dateString) {
    return moment(moment(dateString).toDate().toLocaleString("en-US", { timeZone: timezone })).toDate();
  }
  return new Date();
}

function convertDateStringToUsertimezoneFormat(dateString: string, format?: string) {
  const userTimezone = moment.tz.guess();
  const userTimezoneOffset = new Date().getTimezoneOffset();

  const date = moment(getDateObjectFromGivenTimezone(null, userTimezone, dateString)).subtract(userTimezoneOffset / 60, "hours");

  if (format) {
    return date.format(format);
  }

  return date;
}

function changeTimezone(date: any, ianatz: any) {

  // suppose the date is 12:00 UTC
  var invdate = new Date(date.toLocaleString("en-US", {
    timeZone: ianatz
  }));

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  var diff = date.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract

}

function getTimezoneUTCOffset(timezone: any) {
  return momentTimezone.tz(timezone).utcOffset();
}

function getUTCDateFromTimezoneDate(date: any, timezoneOffset: any) {
  return moment(getDateInGivenTimezone("Etc/GMT", date)).utcOffset(-timezoneOffset);
  // return moment(moment(date).format("YYYY-MM-DD HH:mm:ss")).utcOffset(-timezoneOffset);
}

function getReadableTimezoneOffset(timezone: any) {
  const timezoneOffset = getTimezoneUTCOffset(timezone) / 60;
  const offsetString = timezoneOffset.toString();
  // const timezoneAbbreviation = getTimezoneAbbreviation(timezone);

  // This is to take care of those edge cases where moment is not able to return the abbreviations
  // for some timezones but rather returns offsets.
  // if (!timezoneAbbreviation.includes("-") && !timezoneAbbreviation.includes("+")) {
  if (timezoneOffset > 0) {
    return `${timezoneOffset >= 10 ? '+' : '+0'}${timezoneOffset}`;
  } else {
    if (timezoneOffset === 0) {
      return `+0${timezoneOffset}`;
    } else {
      return `${timezoneOffset <= -10 ? '-' : '-0'}${offsetString.split("-")[1]}`;
    }
  }
  // }
}

function getImageExtension(imageFile: any) {
  if (imageFile) {
    var imageName = imageFile.name;
    var dotIndex = imageName.lastIndexOf(".");
    return imageName.substring(dotIndex + 1);
  }
  return "jpeg";
}

function isQuillEmpty(value: string) {
  if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0 && !value.includes("<img")) {
    return true;
  }
  return false;
}

function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function formatLessonDescription(value: any) {
  if (value) {
    return value.replace(/(?:\r|\n|\r\n)/g, "<br>")
      .replace(/(\b(https?|ftp|file):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z09+&@#\/%=~_|])/img, "<a href=\"$1\">$1</a>");
  }
}

function sanitizeLessonDescription(value: any) {
  if (value) {
    return value.replace(/<br>/g, "\n").replace(/<\/?[^>]+(>|$)/g, "");
  }
}

function getMemberType(roleId: number) {
  switch (roleId) {
    case 1:
      return "Owner";
    case 2:
      return "Admin";
    case 3:
      return "Moderator";
    case 4:
      return "Member";
    default:
      return "Guest";
  }
};

function stripHTML(value: string) {
  if (value) {
    return value.replace(/(<([^>]+)>)/ig, "").replace(/(?:\r|\n|\r\n)/g, "");
  }

  return "";
}

function isLink(value: string) {
  if (value) {
    const linkRegex = new RegExp(/(\b(https?|ftp|file):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z09+&@#\/%=~_|])/img);
    return linkRegex.test(value);
  }

  return false;
}

function getVideoTypeAndIdFromUrl(video_url: any) {
  let videoType: any = "";
  let videoId: any = "";

  if (video_url && video_url.includes("you")) {
    videoType = "youtube";

    let urlParse;
    const videoURL = video_url.split("/");
    videoURL.map((part: any, index: any) => {
      if (part.includes("watch")) {
        urlParse = part.split("=")[1];
        videoId = urlParse;
      } else {
        if (index === 3) {
          videoId = part;
        }
      }
    });
  }

  if (video_url && video_url.includes("vimeo")) {
    videoType = "vimeo";
    // const videoURL = video_url.split("/");
    videoId = video_url;
  }

  if (video_url && video_url.includes("wistia")) {
    videoType = "wistia";
    const videoURL = video_url.split("/");
    videoId(videoURL[4]);
  }

  // if (video_url && video_url.includes("loom")) {
  //   videoType = "loom";
  //   videoId = video_url;
  // }

  return { video_type: videoType, video_id: videoId };
}

function validateFileName(file: any) {
  if (!file) {
    return false;
  }
  const MAX_FILE_NAME_CHARACTER_LIMIT: number = 200;

  if (file && file.name && file.name.length > MAX_FILE_NAME_CHARACTER_LIMIT) {
    toast(`File name exceeds ${MAX_FILE_NAME_CHARACTER_LIMIT} characters`, {
      type: ToastType.WARNING,
      autoClose: 3000,
    });
    return false;
  }

  return true;
}

function objectToQueryString(object: any) {
  return Object.keys(object).map(key => key + '=' + object[key]).join('&');
}

function useNbeaFlow(group: any) {
  if (!group) {
    return false;
  }

  return group.sso_enabled && group.subdomain === "nbea";
}

function OAuthActivated(group: any) {
  if (!group) {
    return false;
  }

  return (
    group.sso_enabled &&
    group.sso_type === SSOMethod.OAUTH &&
    group.subdomain !== "nbea"
  )
}

function customSignUpIsSetUp(group: any) {
  if (!group) {
    return false;
  }

  return (
    group.sso_enabled &&
    group.sso_type === SSOMethod.OAUTH &&
    group.oauth_sso &&
    group.oauth_sso.allow_custom_signup &&
    group.oauth_sso.custom_signup_url
  )
}

function userIsOnResourcePlan(userMembership: any, resourcePlans: Array<any>) {
  if (
    !userMembership ||
    !userMembership.active_subscription ||
    !userMembership.active_subscription.plan ||
    !resourcePlans
  ) {
    return false;
  }

  let planIsAuthorized: boolean = false;
  resourcePlans.forEach((plan: any) => {
    if (plan.id === userMembership.active_subscription.plan.id) {
      planIsAuthorized = true;
    }
  });
  return planIsAuthorized;
}

function userIsOnResourseSegment(userMembership: any, resourceSegments: Array<any>) {
  if (
    !userMembership ||
    !userMembership.segments ||
    !resourceSegments
  ) {
    return false;
  }

  let userIsOnSegment = false;
  resourceSegments.forEach((resourceSegment: any) => {
    userMembership.segments.forEach((userSegment: any) => {
      if (userSegment.id === resourceSegment.id) {
        userIsOnSegment = true
      }
    });
  });

  return userIsOnSegment;
}

function getLockScreenDetails(resource: any) {
  if (resource) {
    const {
      lock_screen_active,
      lock_screen_btn_label,
      lock_screen_btn_url,
      lock_screen_instructions,
      lock_screen_title
    } = resource;

    return {
      lock_screen_active: !!lock_screen_active,
      lock_screen_btn_label: lock_screen_btn_label ? lock_screen_btn_label : '',
      lock_screen_btn_url: lock_screen_btn_url ? lock_screen_btn_url : '',
      lock_screen_instructions: lock_screen_instructions ? lock_screen_instructions : '',
      lock_screen_title: lock_screen_title
    }
  }

  return {
    lock_screen_active: false,
    lock_screen_btn_label: '',
    lock_screen_btn_url: '',
    lock_screen_instructions: '',
  }
}

export function getSizeValues(size?: TSize, isLayout = false): ISizeValue {
  switch (size ? size : "xs") {
    case "xs":
      return {
        height: isLayout ? "10rem" : "1rem",
        width: isLayout ? "10rem" : "1rem"
      }
    case "sm":
      return {
        height: isLayout ? "20rem" : "2rem",
        width: isLayout ? "20rem" : "2rem"
      }
    case "md":
      return {
        height: isLayout ? "30rem" : "3rem",
        width: isLayout ? "30rem" : "3rem"
      }
    case "lg":
      return {
        height: isLayout ? "40rem" : "4rem",
        width: isLayout ? "40rem" : "4rem"
      }
    case "xl":
      return {
        height: isLayout ? "50rem" : "5rem",
        width: isLayout ? "50rem" : "5rem"
      }
    case "2xl":
      return {
        height: isLayout ? "60rem" : "6rem",
        width: isLayout ? "60rem" : "6rem"
      }
    case "3xl":
      return {
        height: isLayout ? "70rem" : "7rem",
        width: isLayout ? "70rem" : "7rem"
      }

    case "4xl":
      return {
        height: isLayout ? "80rem" : "8rem",
        width: isLayout ? "80rem" : "8rem"
      }

    case "5xl":
      return {
        height: isLayout ? "90rem" : "9rem",
        width: isLayout ? "90rem" : "9rem"
      }

    case "6xl":
      return {
        height: isLayout ? "100rem" : "10rem",
        width: isLayout ? "100rem" : "10rem"
      }

    case "7xl":
      return {
        height: isLayout ? "110rem" : "11rem",
        width: isLayout ? "110rem" : "11rem"
      }

    case "fullscreen":
      return {
        height: "100vh",
        width: "100vh"
      }

    default:
      return {
        height: isLayout ? "50rem" : "5rem",
        width: isLayout ? "50rem" : "5rem"
      }
  }
}

const getReorderedResourcesUsingDraggable = (result: any, resourcesToBeOrdered: Array<any>) => {
  //user dropped outside context
  if (!result.destination) {
    return resourcesToBeOrdered;
  }

  //user dropped to previous location
  if (
    result.source.droppableId === result.destination.droppableId &&
    result.source.index === result.destination.index
  ) {
    return resourcesToBeOrdered;
  }

  //find the dragged resource
  let draggedResource: any;

  resourcesToBeOrdered.forEach((resource: any) => {
    if (`${resource.id}` === `${result.draggableId}`) {
      draggedResource = resource;
    }
  })

  const reorderedResources = Array.from(resourcesToBeOrdered);

  if (draggedResource) {
    reorderedResources.splice(result.source.index, 1);
    reorderedResources.splice(result.destination.index, 0, draggedResource);
  }

  return reorderedResources;
}

function toSentenceCase(str: string) {
  let _tmp = str.toLowerCase();
  let result = _tmp
    .replace(/[a-z]/i, function (letter) {
      return letter.toUpperCase();
    })
    .trim();

  return result;
}

export function removeTags(str: any) {
  if ((str === null) || (str === ''))
    return false;
  else
    str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/ig, '');
}

export function decodeHtml(html: string) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export function removeFormdataExtraNewLines(str: any) {
  if ((str === null) || (str === ''))
    return false;
  else
    str = str.toString();

  return str.replace(/\/r/i, '');
}

function isNumeric(value: any) {
  return !isNaN(value)
}

function getGroupSubdomainFromSlug(slug: string) {
  let subdomain;

  if (!slug) {
    return null;
  }

  const sub = slug.split("://")[1];

  if (sub) {
    subdomain = sub.split(".")[0];
  }

  return subdomain;
}

function getListFromEnum(data: any): any[] {
  const list: any[] = [];

  Object.keys(data).forEach((key) => {
    list.push(sentenceCase(data[key]));
  });

  return list;
}

function sentenceCase(str: string) {
  let _tmp = str.toLowerCase();
  let result = _tmp
    .replace(/[a-z]/i, function (letter) {
      return letter.toUpperCase();
    })
    .trim();

  return result;
}

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const getUserSubscriptionTrialEndDate = (plan: any) => {
  if (plan && plan.trial_days && plan.trial_days > 0) {
    return moment(new Date()).add(plan.trial_days, "days").toDate();
  }
}

const userTrialEndDate = (group: any) => {
  return (group && group.user_membership && group.user_membership.active_subscription && group.user_membership.active_subscription.trial_ends_at);
}

const getUserSubscriptionStartDate = (group: any) => {
  return (group && group.user_membership && group.user_membership.active_subscription && group.user_membership.active_subscription.created_at);
}

const userIsOnTrial = (group: any) => {
  return (group && group.user_membership && group.user_membership.active_subscription && group.user_membership.active_subscription.on_trial);
}

const getTrialDaysRemaining = (group: any) => {
  const now = moment();

  if (group.user_membership) {
    const trialEndsDate = moment(userTrialEndDate(group));

    if (trialEndsDate) {
      return trialEndsDate.diff(now, "days");
    }
  }
  return 0;
}

const getTrialDaysUsed = (group: any) => {
  const now = moment();

  if (group.user_membership) {
    const subscriptionStartDate = moment(getUserSubscriptionStartDate(group));

    if (subscriptionStartDate) {
      return now.diff(subscriptionStartDate, "days");
    }
  }
  return 0;
}

const getProratedTrialDays = (group: any, plan: any) => {
  const trialDaysUsed = getTrialDaysUsed(group);

  if (group.user_membership) {
    if (plan && trialDaysUsed) {
      const proratedTrialDays = plan.trial_days - trialDaysUsed;

      if (proratedTrialDays > 0) {
        return proratedTrialDays;
      }
    }

    return 0;
  }

  return plan.trial_days;

}

const isSubscriptionPaymentFailedOrPastDue = (group: any) => {
  return group && group.user_membership &&
    (
      group.user_membership.subscription_status === USER_SUBSCRIPTION_STATUS.PAST_DUE ||
      (
        group.user_membership.active_subscription &&
        group.user_membership.active_subscription.stripe_status === USER_SUBSCRIPTION_STATUS.PAST_DUE
      )
    ) &&
    !isOwner(group.user_membership) &&
    !isAdmin(group.user_membership) &&
    !isModerator(group.user_membership) &&
    !isMembershipPendingApproval(group.user_membership)
}

const isSubscriptionPaymentCanceled = (group: any) => {
  return group && group.user_membership &&
    !group.user_membership.active_subscription &&
    !group.user_membership.temp_plan &&
    !isOwner(group.user_membership) &&
    !isAdmin(group.user_membership) &&
    !isModerator(group.user_membership) &&
    !isMembershipPendingApproval(group.user_membership)
}

const isOneTimeSubscriptionPlan = (plan: any): boolean => {
  return plan && plan.interval === SUBSCRIPTION_PLAN_INTERVALS.ONE_TIME;
}

const userHasCardOnFile = (group: any): boolean => {
  return !!(group &&
    group.user_membership &&
    group.user_membership.pm_type &&
    group.user_membership.pm_last_four)
}

const isSameDayEvent = (event: any) => {
  const userTimezone = moment.tz.guess();
  const userTimezoneOffset = new Date().getTimezoneOffset();

  const startDate = moment(getDateObjectFromGivenTimezone(null, userTimezone, event.end_time)).subtract(userTimezoneOffset / 60, "hours");
  const endDate = moment(getDateObjectFromGivenTimezone(null, userTimezone, event.start_time)).subtract(userTimezoneOffset / 60, "hours");

  return startDate.isSame(endDate, "day");
}

const newMemberCheck = (isAuthed: any, group: any) => {
  const onboardingDateAfterTheDeployement = moment('Mon Mar 18 2024 13:00:00', 'ddd MMM DD YYYY HH:mm:ss');
  return Boolean(
    isAuthed &&
    group &&
    group.user_membership &&
    (
      group.user_membership.approved_on === null ||
      (
        group.user_membership.approved_on &&
        moment.utc(group.user_membership.approved_on).isAfter(moment.utc(onboardingDateAfterTheDeployement)) &&
        moment.utc(group.user_membership.approved_on).isSameOrAfter(moment.utc().subtract(14, 'days'))
      )
    )
  );
}

const onboardingPopUpShouldOpen = (group: any, isAuthed: boolean, requiredDefaultKeys: any) => {
  // determine if onboarding pop up should open
  // This should be case if the case the list length is greater than 0

  const customFieldsAreSet = Boolean(
    isAuthed &&
    group &&
    group.onboarding_steps &&
    group.onboarding_steps.custom_fields
  )

  const defaultFieldsAreSet = Boolean(
    isAuthed &&
    group &&
    group.onboarding_steps &&
    group.onboarding_steps.default_fields
  )

  const welcomeScreenIsSet = Boolean(
    isAuthed &&
    group &&
    group.user_membership &&
    group.user_membership.approved_on &&
    group.onboarding_steps &&
    group.onboarding_steps.welcome
  )

  const IntroductionScreenIsSet = Boolean(
    isAuthed &&
    group &&
    group.user_membership &&
    group.user_membership.approved_on &&
    group.onboarding_steps &&
    group.onboarding_steps.introduction
  )

  const customFieldsStepIsCompleted = Boolean(
    isAuthed &&
    group &&
    group.user_membership &&
    group.user_membership.onboarding_completed &&
    group.user_membership.onboarding_completed.custom_fields
  )

  const defaultFieldsStepIsCompleted = Boolean(
    isAuthed &&
    group &&
    group.user_membership &&
    group.user_membership.onboarding_completed &&
    group.user_membership.onboarding_completed.default_fields
  )

  const welcomeScreenStepIsCompleted = Boolean(
    isAuthed &&
    group &&
    group.user_membership &&
    group.user_membership.onboarding_completed &&
    group.user_membership.onboarding_completed.welcome
  )

  const IntroductionScreenStepIsCompleted = Boolean(
    isAuthed &&
    group &&
    group.user_membership &&
    group.user_membership.onboarding_completed &&
    group.user_membership.onboarding_completed.introduction
  )

  const welcomeScreenIsSetForBoth = Boolean(
    isAuthed &&
    group &&
    group.user_membership &&
    group.user_membership.approved_on &&
    !isTeamMember(group.user_membership) &&
    group.onboarding_steps &&
    group.onboarding_steps.required_welcome
  )

  const defaultFieldsAreSetForBoth = Boolean(
    isAuthed &&
    group &&
    !isTeamMember(group.user_membership) &&
    group.onboarding_steps &&
    group.onboarding_steps.required_default_fields
  )


  const customFieldsAreSetForBoth = Boolean(
    isAuthed &&
    group &&
    !isTeamMember(group.user_membership) &&
    group.onboarding_steps &&
    group.onboarding_steps.required_custom_fields
  )

  const IntroductionScreenIsSetForBoth = Boolean(
    isAuthed &&
    group &&
    group.user_membership &&
    group.user_membership.approved_on &&
    !isTeamMember(group.user_membership) &&
    group.onboarding_steps &&
    group.onboarding_steps.required_introduction
  )

  const onboardingStepsList = []

  if (newMemberCheck(isAuthed, group)) {
    if (welcomeScreenIsSet && !welcomeScreenStepIsCompleted) {
      onboardingStepsList.push('welcome')
    }
    if (defaultFieldsAreSet && !defaultFieldsStepIsCompleted && requiredDefaultKeys.length > 0
    ) {
      onboardingStepsList.push('default_fields')
    }
    if (customFieldsAreSet && !customFieldsStepIsCompleted) {
      onboardingStepsList.push('custom_fields')
    }
    if (IntroductionScreenIsSet && !IntroductionScreenStepIsCompleted) {
      onboardingStepsList.push('introduction')
    }
  }
  else {
    if (welcomeScreenIsSet && !welcomeScreenStepIsCompleted && welcomeScreenIsSetForBoth) {
      onboardingStepsList.push('welcome')
    }
    if (defaultFieldsAreSet && requiredDefaultKeys.length > 0 && defaultFieldsAreSetForBoth) {
      onboardingStepsList.push('default_fields')
    }
    if (customFieldsAreSet && !customFieldsStepIsCompleted && customFieldsAreSetForBoth) {
      // onboardingStepsList.push('custom_fields')
    }
    if (IntroductionScreenIsSet && !IntroductionScreenStepIsCompleted && IntroductionScreenIsSetForBoth) {
      onboardingStepsList.push('introduction')
    }
  }
  return onboardingStepsList

}

const slugify = (string: string) => {
  return string
    .toLowerCase() // Convert the string to lowercase
    .replace(/[^\w\s-]/g, '') // Remove non-word characters except spaces and hyphens
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-') // Replace multiple hyphens with a single hyphen
    .trim(); // Trim leading and trailing spaces
}

const validateSpecialChars = (imageFile: File) => {
  const specialChars = /[#%]/g;
  if (specialChars.test(imageFile.name)) {
    toast(`Please remove any special characters like # or % from the file name: ${imageFile.name}`, {
      type: ToastType.ERROR,
      autoClose: 5000,
    });
    return false;
  }
  return true
}

const convertHeicToPng = async (file: any) => {
  try {
    if (typeof window === "undefined" || !heic2any) {
      throw new Error("heic2any is not available in this environment");
    }

    const convertedBlob = await heic2any({
      blob: file,
      toType: 'image/png',
    });
    return convertedBlob;
  } catch (error) {
    console.error('Error converting HEIC to PNG:', error);
    return null;
  }
};

function getSubdomainIndex(hostname: string): number {
  const parts = hostname.split('.').reverse();
  if (parts.length === 1) return 0; // 'com'
  if (parts.length === 2) return 1; // 'example.com'
  return 2; // 'www.example.com' or more
}

function isActiveCommunityMember(userMembership: any): boolean {
  return (
    isTeamMember(userMembership) ||
    (
      userMembership &&
      userMembership.active_subscription &&
      (
        userMembership.active_subscription.stripe_status === "active" ||
        userMembership.active_subscription.stripe_status === "trialing"
      )
    )
  )
}

function isChannelMember(userMembership: any, channelData: any): boolean {
  return (
    isTeamMember(userMembership) ||
    (
      channelData &&
      channelData.membership &&
      !channelData.membership.deleted_at
    )
  )
}

function isMembershipPendingApproval(userMembership: any): boolean {
  return (
    userMembership &&
    !userMembership.active_subscription &&
    userMembership.temp_plan
  )
}

// Utility function to format date to 'YYYY-MM-DD HH:MM:SS'
const getDateTimeString = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

// Function to add days to today's date
const addDaysToDate = (newDate: Date, days: number) => {
  const date = new Date(newDate);
  date.setDate(date.getDate() + days);
  return getDateTimeString(date);
};

const MaxPhotoFileSize: number = 5000000;

const validatePhotoFileSize = (file: any): boolean => {
  if (file && file.size > MaxPhotoFileSize) {
    toast("Maximum image upload size is 5MB.", {
      type: ToastType.ERROR,
      autoClose: 3000,
    });
    return false;
  }
  return true;
}

const getBunnyMP4VideoUrl = (bunnyLibraryType: BunnyLibraryTypes, videoUrl: string, resolution?: string) => {

  const getVideoId = () => {
    return videoUrl.split("/")[5];
  }

  switch (bunnyLibraryType) {
    case BunnyLibraryTypes.COURSE_INTRO:
      return `https://${appConfig.bunny.course_intro_videos.pull_zone}.b-cdn.net/${getVideoId()}/play_${resolution ? resolution : '720'}p.mp4`;
    case BunnyLibraryTypes.COURSE_CONTENT:
      return `https://${appConfig.bunny.course_content_videos.pull_zone}.b-cdn.net/${getVideoId()}/play_${resolution ? resolution : '720'}p.mp4`;
    case BunnyLibraryTypes.STANDALONE_LIBRARY_CONTENT:
      return `https://${appConfig.bunny.standalone_library_videos.pull_zone}.b-cdn.net/${getVideoId()}/play_${resolution ? resolution : '720'}p.mp4`
    default:
      return `https://${appConfig.bunny.post_videos.pull_zone}.b-cdn.net/${getVideoId()}/play_${resolution ? resolution : '720'}p.mp4`;
  }
}

const encodeURLLastPathSegment = (url: string) => {
  if (!url) {
    return '';
  }
  const urlParts = url.split('/');

  const lastPathSegment = urlParts.pop();

  const remainingURLString = urlParts.join('/');

  return `${remainingURLString}/${encodeURIComponent(lastPathSegment ? lastPathSegment : '')}`;
}

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  });
}

function isEmptyText(htmlString: any) {
  // Check for image tags
  if (/<img/.test(htmlString)) {
    return false; // Contains an image, so not empty
  }

  // Convert the HTML string to ContentState
  const blocksFromHTML = convertFromHTML(htmlString);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  // Check if the content is empty
  return contentState.getPlainText().trim() === '';
}

function postDescCount(htmlString: any) {
  // Remove image tags from the HTML string
  const htmlWithoutImages = htmlString.replace(/<img[^>]*>/g, "");

  // Convert the HTML string to ContentState
  const blocksFromHTML = convertFromHTML(htmlWithoutImages);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  // Get the plain text and return its length
  const plainText = contentState.getPlainText().trim();
  return plainText.length; // Return the length of the plain text
}


function truncateString(str: string, num: number) {
  // Check if the length of the string is greater than the given number
  if (str.length > num) {
    // If it is, truncate the string and add '...' at the end
    return str.slice(0, num) + '...';
  } else {
    // If it's not, return the string as is
    return str;
  }
}

const replaceNbspWithSpace = (inputString: string) => {
  var replacedString = inputString.replace(/&nbsp;/g, ' ');

  return replacedString;
}

const groupStorageLimitIsMaxedOut = (group: any) => {
  if(
    group &&
    group.max_str
  ) {
    return (
      group &&
      group.str_usg &&
      group.str_usg >= group.max_str
    )
  }

  return (
    group &&
    group.str_usg &&
    group.active_subscription &&
    group.active_subscription.plan &&
    group.active_subscription.plan.storage_limit &&
    group.str_usg >= group.active_subscription.plan.storage_limit
  )
}

function isWithinPastXDays(date: string, days: number) {
  if(!date) {
    return false;
  }

  const currentDate = dayjs().utc();
  const targetDate = dayjs.utc(date);

  // Check if the date is within the last X days
  return targetDate.isBetween(currentDate.subtract(days, 'day'), currentDate, null, '[]');
}

export default {
  isAdmin,
  isOwner,
  isModerator,
  isTeamMember,
  logger,
  getRandomColor,
  getBillingFrequency,
  prepareGroupPlans,
  updateObject,
  getStateSlice,
  getNameInitials,
  getColorInitial,
  convertGroupLink,
  convertWindowsLink,
  getUrlFromString,
  getGroupSlugChannel,
  getDomainFromUrl,
  stringCapitalisation,
  formatDuration,
  formatSecondsDuration,
  filterNumbers,
  blobToFile,
  getRedirectBaseUrl,
  getBaseDomain,
  getChannelLinksColor,
  getBlockButtonStyle,
  getTextButtonStyle,
  supportVideoFormats,
  getAccessLabel,
  secondsToContentDuration,
  getOutlineButtonStyle,
  getBlockInverseButtonStyle,
  customThemeSet,
  getCoursePriceLabelStyle,
  getPlanInterval,
  formatGroupPlans,
  toTitleCase,
  getCourseButtonAction,
  getCourseButtonActionText,
  startCourse,
  sanitizeLibraryContentSlug,
  objectToQueryString,
  toLowerCase,
  sanitizeQuillValue,
  mBToGb,
  getDateDiffInDays,
  getDateDiffInHours,
  isTrialPast,
  getAvailableCurrencies,
  getCurrencySymbol,
  getResourceCurrency,
  getTimeFromDate,
  getFormattedDateFromDate,
  getDateFromString,
  getGroupPlan,
  isGroupOnPlan,
  updateArrayObject,
  getDateInCurrentTimezone,
  getUserTimezone,
  getTimezoneAbbreviation,
  getUserTimezoneAbbreviation,
  getUserPlan,
  getTimezoneDateFromString,
  getDateInGivenTimezone,
  getCommunityTimezone,
  getDateObjectFromGivenTimezone,
  changeTimezone,
  getTimezoneUTCOffset,
  getUTCDateFromTimezoneDate,
  getImageExtension,
  isQuillEmpty,
  hexToRgb,
  formatLessonDescription,
  sanitizeLessonDescription,
  getMemberType,
  stripHTML,
  isLink,
  getReadableTimezoneOffset,
  getVideoTypeAndIdFromUrl,
  validateFileName,
  getOutButtonStyle: getOutlineButtonStyle,
  useNbeaFlow,
  OAuthActivated,
  userIsOnResourseSegment,
  getLockScreenDetails,
  userIsOnResourcePlan,
  customSignUpIsSetUp,
  isGroupOnFreePlan,
  isGroupMember,
  convertDateStringToUsertimezoneFormat,
  getReorderedResourcesUsingDraggable,
  toSentenceCase,
  removeTags,
  decodeHtml,
  removeFormdataExtraNewLines,
  isNumeric,
  getGroupSubdomainFromSlug,
  getListFromEnum,
  sentenceCase,
  validateEmail,
  getPlanIntervally,
  getUserSubscriptionTrialEndDate,
  getPlanCycle,
  isSubscriptionPaymentFailedOrPastDue,
  isOneTimeSubscriptionPlan,
  userHasCardOnFile,
  userTrialEndDate,
  userIsOnTrial,
  getTrialDaysRemaining,
  getTrialDaysUsed,
  getUserSubscriptionStartDate,
  getProratedTrialDays,
  isSubscriptionPaymentCanceled,
  isSameDayEvent,
  onboardingPopUpShouldOpen,
  newMemberCheck,
  slugify,
  validateSpecialChars,
  convertHeicToPng,
  getSubdomainIndex,
  getDateTimeString,
  addDaysToDate,
  isActiveCommunityMember,
  isChannelMember,
  isMembershipPendingApproval,
  MaxPhotoFileSize,
  validatePhotoFileSize,
  getBunnyMP4VideoUrl,
  encodeURLLastPathSegment,
  scrollToTop,
  isEmptyText,
  postDescCount,
  truncateString,
  replaceNbspWithSpace,
  groupStorageLimitIsMaxedOut,
  isWithinPastXDays,
};
