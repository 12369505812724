import { ISlideoutProfileData } from "types";
import { GlobalActionType } from "../../constants/globalActionType";
import { getlocalStorageValue, setlocalStorageValue } from "../../utils/localStorage";

// const commonService: ICommonService = provider.get<ICommonService>(SocialProviderTypes.CommonService);

export const setDsiableLogout = (value: boolean) => {
  return {
    type: GlobalActionType.SET_DISABLE_LOGOUT,
    payload: value,
  }
}

export const showMessage = (message: any) => {
  if (!message || message === "") {
    message = "Bad request";
  }
  return {
    type: GlobalActionType.SHOW_MESSAGE_GLOBAL,
    payload: message
  };
};

export const errorMessage = () => {
  return {
    type: GlobalActionType.ERROR_FROM_SERVER
  };
};

export const setCurrentViewOpt = (searchQuery: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(setCurrentView(searchQuery));
  };
};

export const setCurrentSlug = (slug: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(setCurrentSlugs(slug));
  };
};

export const setChannelSidebar = (status: boolean) => {
  return {
    type: GlobalActionType.SET_CHANNEL_SIDEBAR_STATUS,
    payload: status
  };
};

export const setSidebarForChannel = (status: boolean) => {
  return {
    type: GlobalActionType.SET_SIDEBAR_FOR_CHANNEL,
    payload: status
  };
};

export const setSelectedGroup = (group: any) => {
  return {
    type: GlobalActionType.SET_SELECTED_GROUP,
    payload: group
  };
};

export const setGroupTheme = (theme: any) => {
  return {
    type: GlobalActionType.SET_GROUP_THEME,
    payload: theme
  };
};

export const goToSidebarChannel = (channelId: any) => {
  return {
    type: GlobalActionType.GOT_TO_SIDEBAR_CHANNEL,
    payload: channelId
  };
};

export const refreshSidebar = () => {
  return {
    type: GlobalActionType.REFRESH_SIDEBAR
  };
};

export const setHeaderTitleOpt = (callerKey: string, payload: any) => {
  return (dispatch: any, getState: any) => {
    switch (callerKey) {
      case "profile":
        return {
          type: GlobalActionType.SHOW_MESSAGE_GLOBAL,
          payload: {}
        };
    }
  };
};

export const setCurrentView = (searchQuery: string) => {
  return {
    searchQuery,
    type: GlobalActionType.SET_CURRENT_VIEW
  };
};
export const setCurrentSlugs = (slug: string) => {
  return {
    type: GlobalActionType.SET_CURRENT_SLUG,
    payload: slug
  };
};

export const setHeaderTitle = (text: string) => {
  return {
    type: GlobalActionType.SET_HEADER_TITLE,
    payload: text
  };
};

export const setPostsQuery = (searchQuery: string) => {
  return {
    searchQuery,
    type: GlobalActionType.SET_POSTS_QUERY
  };
};

export const showTopLoading = () => {
  return {
    type: GlobalActionType.SHOW_TOP_LOADING
  };
};

export const hideTopLoading = () => {
  return {
    type: GlobalActionType.HIDE_TOP_LOADING
  };
};

export const showMasterLoading = () => {
  return {
    type: GlobalActionType.SHOW_MASTER_LOADING
  };
};

export const hideMasterLoading = () => {
  return {
    type: GlobalActionType.HIDE_MASTER_LOADING
  };
};

export const toggleHamburgerMenu = () => {
  const sideBarStatus = getlocalStorageValue("sidebar") && getlocalStorageValue("sidebar").value;

  if (sideBarStatus === null) {
    setlocalStorageValue("sidebar", false);
  } else {
    setlocalStorageValue("sidebar", !sideBarStatus);
  }

  return {
    type: GlobalActionType.TOGGLE_HAMBURGER_MENU
  };
};

export const closeHamburgerMenu = () => {
  setlocalStorageValue("sidebar", false);

  return {
    type: GlobalActionType.CLOSE_HAMBURGER_MENU
  }
}

export const openHamburgerMenu = () => {
  setlocalStorageValue("sidebar", true);

  return {
    type: GlobalActionType.OPEN_HAMBURGER_MENU
  }
}

export const clearMessage = () => {
  return {
    type: GlobalActionType.SHOW_MESSAGE_GLOBAL,
    payload: {}
  };
};

export const setSidebarVisibility = (status: boolean) => {
  return {
    type: GlobalActionType.TOGGLE_HAMBURGER_MENU,
    payload: status
  };
};

export const showPublicGroupLogin = () => {
  return {
    type: GlobalActionType.SHOW_PUBLIC_GROUP_LOGIN
  };
};

export const showPublicGroupSignup = () => {
  return {
    type: GlobalActionType.SHOW_PUBLIC_GROUP_SIGNUP
  };
};

export const modalIsOpen = () => {
  return {
    type: GlobalActionType.MODAL_IS_OPEN
  };
};

export const toggleUserProfileSlider = () => {
  return {
    type: GlobalActionType.TOGGLE_USER_PROFILE_SLIDER, 
  };
};

export const openUserProfileSlider = () => {

  return {
    type: GlobalActionType.OPEN_USER_PROFILE_SLIDER,
    payload: true
  }
}

export const closeUserProfileSlider = () => {

  return {
    type: GlobalActionType.CLOSE_USER_PROFILE_SLIDER,
    payload: false
  }
}

export const setSlideoutProfileData = (data: ISlideoutProfileData) => {
  return {
    type: GlobalActionType.SET_SLIDEOUT_PROFILE_DATA,
    payload: data
  };
};

export const clearSlideoutProfileData = () => {
  return {
    type: GlobalActionType.SET_SLIDEOUT_PROFILE_DATA,
    payload: {}
  };
};
