import { Container } from "inversify";
import miscUtils from "./utils/miscUtils";
import { useAws } from "./api/dependencyRegistrar";

if(typeof window !== 'undefined') {
    window.console.trace = miscUtils.logger;
}
const provider = new Container();

useAws(provider);

export { provider }
