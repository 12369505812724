import miscUtils from "../../../utils/miscUtils";

export class Post {
  // Index Signature
  [key: string]: any;

  public id: number = -1;
  public channel_id: number = -1;
  public all_comments_count: number = 0;
  public comments_count: number = -1;
  public created_at: string = "";
  public content: string = "";
  public group_id: number = -1;
  public liked: boolean = false;
  public reaction_like_count: number = -1;
  public reaction_like_weight: string = "";
  public time_difference: string = "";
  public updated_at: string = "";
  public pinned_at: any;
  public meta_data: any;
  public file_size: number = 0;
  public file_is_downloadable: boolean = false;
  public original_file_name: string = "";
  public group: any = null;
  public author: any = null; // TODO replace with Author or User
  public author_role_id: any;
  public post_settings: any;
  public comments: any;
  public title: any;
  public type: any;
  public description: any;
  public description_json: any;
  public media_url: any;
  public media_thumbnail: any;
  public postable_id: any;
  public postable_type: any;
  public postable: any;
  public published_at: any;
  public storage_id: any;
  public stream_id: any;
  public stream_status: any;
  public cover_image_url: any;
  public cover_image_file_name: any;
  public slug: any;
  public commenting_closed: any;
  public files: any
  public featured_at: any
  public comment: any;
  public is_assignment: any
  constructor(instanceData?: Post) {
    if (instanceData) {
      this.deserialize(instanceData);
    }
  }

  private deserialize(instanceData: Post) {
    const keys: string[] = Object.keys(this);
    for (const key of keys) {
      if (instanceData.hasOwnProperty(key)) {
        this[key] = instanceData[key];
      }
    }
  }

  public get $updated_at(): Date {
    return new Date(this.updated_at);
  }

  public get $created_at(): Date {
    return new Date(this.created_at);
  }

  public get url(): string {
    let postUrl = miscUtils.getUrlFromString(this.content);
    if (postUrl && postUrl[0]) {
      postUrl = postUrl[0];
    }
    return postUrl;
  }
}
