import { injectable } from "inversify";
import { User } from "../../../core/models/users";
import { RegisterUserResult } from "../../../core/models/authorize";
import { SocialError } from "../../../core/models/common";
import {
  removeCookieValue,
  setCookieValue
} from "../../../utils/cookieStorage";
import databaseInstance from "../../index";
import { IAuthorizeService } from "../../../core/services/authorize";
import { headerify } from "../../APIUtils";
import { jwtDecode } from "jwt-decode";
import Cookie from "js-cookie";
import { CommonConstants } from "../../../constants/common";

@injectable()
export class AuthorizeService implements IAuthorizeService {
  // @ts-ignore
  public dbInstance: any = databaseInstance;
  private cb: any;
  private static instance: AuthorizeService;

  constructor() {
    AuthorizeService.instance = this;
  }

  public static getInstance() {
    if (!AuthorizeService.instance) {
      AuthorizeService.instance = new AuthorizeService();
    }
    return AuthorizeService.instance;
  }

  public getAuthorizableUser: (response: any) => Promise<any> = (
    response,
  ) => {
    return new Promise<any>((resolve, reject) => {
      const user: any = { ...response.user, ...response.data };
      if (user) {
        delete user.user.about_me;
        setCookieValue(CommonConstants.USER_KEY, user.user);
        setCookieValue(CommonConstants.USER_TOKEN, user.token);
        // setCookieValue("sidebar", true);
        removeCookieValue("inviterGroup");
        this.cb(user.user);
        resolve(user.user);
      } else {
        throw new SocialError(
          "AuthorizeService/login",
          "User object is invalid!"
        );
      }
    })
  }

  public login: (email: string, password: string, ip: any, ua: any) => Promise<any> = (
    email,
    password,
    ip,
    ua
  ) => {
    this.dbInstance.interceptors.request.use(headerify);
    return this.dbInstance
      .post("/login", { email, password, ip, ua })
      .then((result: any) => {
        const user: any = { ...result.user, ...result.data };
        if (user) {
          delete user.user.about_me;
          delete user.user.automated_email_notification;
          setCookieValue(CommonConstants.USER_KEY, user.user);
          setCookieValue(CommonConstants.USER_TOKEN, user.token);
          // setCookieValue("sidebar", true);
          removeCookieValue("inviterGroup");
          this.cb(user.user);
          return user.user;
        }
        throw new SocialError(
          "AuthorizeService/login",
          "User object is invalid!"
        );
      })
  };

  public async registerUser(user: User) {
    try {
      const payload = { ...user };
      const result = await databaseInstance.post("/users", payload);
      const createdUser: any = { ...result.data.user, ...result.data };
      if (createdUser) {
        setCookieValue(CommonConstants.USER_KEY, createdUser.user);
        setCookieValue(CommonConstants.USER_TOKEN, createdUser.token);
        this.cb(createdUser.user);
        return new RegisterUserResult(createdUser.user.id);
      }
      throw new SocialError(
        "AuthorizeService/login",
        "User object is invalid!"
      );
    } catch (error) {
      throw new SocialError(error.status, error.data.errors);
    }
  }

  public async getRegisteringUser(result: any) {
    try {
      const createdUser: any = { ...result.data.user, ...result.data };
      if (createdUser) {
        setCookieValue(CommonConstants.USER_KEY, createdUser.user);
        setCookieValue(CommonConstants.USER_TOKEN, createdUser.token);
        this.cb(createdUser.user);
        return new RegisterUserResult(createdUser.user.id);
      }
      throw new SocialError(
        "AuthorizeService/login",
        "User object is invalid!"
      );
    } catch (error) {
      throw new SocialError(error.status, error.data.errors);
    }
  }

  public async forgotPassword(email: string, groupId?: any) {
    const payload = {
      email
    };

    if (groupId) {
      //@ts-ignore
      payload.group_id = groupId;
    }
    const result = await databaseInstance.post("/password/email", payload);
    return result;
  }

  public resetPassword: (
    email: string,
    verification: string,
    password: string,
    passwordConfirmation: string
  ) => Promise<any> = (email, verification, password, passwordConfirmation) => {
    return this.dbInstance.post("/password/reset", {
      email,
      password,
      token: verification,
      password_confirmation: passwordConfirmation
    });
  };

  public verifyPasswordToken: (
    email: string,
    token: string,
  ) => Promise<any> = (email, token) => {
    return this.dbInstance.post("/password/validate-token", {
      email,
      token,
    });
  };

  public verifyAccount: (userId: number, code: number) => Promise<any> = (
    userId,
    code
  ) => {
    return this.dbInstance.post(`/email/verify/${userId}/${code}`, {});
  };

  public resendVerificationCode: (groupId?: number) => Promise<any> = (groupId) => {
    if (groupId) {
      return this.dbInstance.post(`/email/resend`, { group_id: groupId });
    }
    return this.dbInstance.post(`/email/resend`, {});
  };

  public onAuthStateChanged: (callBack: any) => void = callBack => {
    this.cb = callBack;
    let userData: any = Cookie.get(CommonConstants.USER_KEY);
    const token: any = Cookie.get(CommonConstants.USER_TOKEN);
    if (userData && token) {
      userData = JSON.parse(userData);

      const tokenData: any = jwtDecode(token);
      const now = new Date();
      if (tokenData.exp < now.getTime() / 1000) {
        callBack(null);
        return;
      }
      callBack(userData);
    } else {
      removeCookieValue(CommonConstants.USER_KEY);
      callBack(null);
    }
  };

  public logout() {
    removeCookieValue(CommonConstants.USER_KEY);
    removeCookieValue(CommonConstants.USER_TOKEN);
    localStorage.clear();
    this.cb(null);
  }

  public confirmInviteEmail: (
    groupId: string,
    email: string,
    inviteConfigId: number,
  ) => Promise<any> = (groupId, email, inviteConfigId) => {
    return this.dbInstance.post(`/groups/${groupId}/invitation-configs/${inviteConfigId}/email-check`, {
      email
    });
  };
}
