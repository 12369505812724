export const SocialProviderTypes = {
  AuthorizeService: Symbol("AuthorizeService"),
  GroupService: Symbol("GroupService"),
  UserTieService: Symbol("UserTieService"),
  CircleService: Symbol("CircleService"),
  CommentService: Symbol("CommentService"),
  CommonService: Symbol("CommonService"),
  StorageService: Symbol("StorageService"),
  ImageGalleryService: Symbol("ImageGalleryService"),
  NotificationService: Symbol("NotificationService"),
  PostService: Symbol("PostService"),
  UserService: Symbol("UserService"),
  VoteService: Symbol("VoteService")
};
