export enum RoleIds {
  Owner = 1,
  Admin = 2,
  Moderator = 3,
  Member = 4
}

export enum Plans {
  Free = "free",
  Basic = "basic",
  Pro = "pro",
  Premium = "premium"
}

export enum TeamPlanLimits {
  Free = 1,
  Pro = 10,
  Premium = 100
}
