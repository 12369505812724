import { SocialProviderTypes } from "../core/socialProviderTypes";
import { AuthorizeService } from "./services/authorize";
import { IAuthorizeService } from "../core/services/authorize";
import { Container } from "inversify";
import { IGroupService } from "../core/services/groups";
import { GroupService } from "./services/groups";
import { IUserService } from "../core/services/users";
import { UserService } from "./services/users";
import { INotificationService } from "../core/services/notification";
import { NotificationService } from "./services/notification";

export const useAws = (container: Container) => {
  container
    .bind<IAuthorizeService>(SocialProviderTypes.AuthorizeService)
    .to(AuthorizeService);
  container
    .bind<IGroupService>(SocialProviderTypes.GroupService)
    .to(GroupService);
  container.bind<IUserService>(SocialProviderTypes.UserService).to(UserService);
  container
    .bind<INotificationService>(SocialProviderTypes.NotificationService)
    .to(NotificationService);
};
