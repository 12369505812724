export enum GlobalActionType {
  HANDLE_CLEAR_STORE = "HANDLE_CLEAR_STORE",
  PROGRESS_CHANGE = "PROGRESS_CHANGE",
  DEFAULT_DATA_DISABLE = "DEFAULT_DATA_DISABLE",
  DEFAULT_DATA_ENABLE = "DEFAULT_DATA_ENABLE",
  LOADING = "LOADING",
  HIDE_MESSAGE_GLOBAL = "HIDE_MESSAGE_GLOBAL",
  SHOW_MESSAGE_GLOBAL = "SHOW_MESSAGE_GLOBAL",
  SHOW_NORMAL_MESSAGE_GLOBAL = "SHOW_NORMAL_MESSAGE_GLOBAL",
  SET_HEADER_TITLE = "SET_HEADER_TITLE",
  SHOW_TOP_LOADING = "SHOW_TOP_LOADING",
  HIDE_TOP_LOADING = "HIDE_TOP_LOADING",
  SHOW_MASTER_LOADING = "SHOW_MASTER_LOADING",
  HIDE_MASTER_LOADING = "HIDE_MASTER_LOADING",
  SHOW_SEND_FEEDBACK = "SHOW_SEND_FEEDBACK",
  HIDE_SEND_FEEDBACK = "HIDE_SEND_FEEDBACK",
  TEMP = "TEMP",
  CLEAR_ALL_GLOBAL = "CLEAR_ALL_GLOBAL",
  OPEN_POST_WRITE = "OPEN_POST_WRITE",
  CLOSE_POST_WRITE = "CLOSE_POST_WRITE",
  INIT_LOCALE = "INIT_LOCALE",
  TOGGLE_HAMBURGER_MENU = "TOGGLE_HAMBURGER_MENU",
  CLOSE_HAMBURGER_MENU = "CLOSE_HAMBURGER_MENU",
  OPEN_HAMBURGER_MENU = "OPEN_HAMBURGER_MENU",
  SET_CURRENT_VIEW = "SET_CURRENT_VIEW",
  SET_CURRENT_SLUG = "SET_CURRENT_SLUG",
  SET_CHANNEL_SIDEBAR_STATUS = "SET_CHANNEL_SIDEBAR_STATUS",
  SET_SIDEBAR_FOR_CHANNEL = "SET_SIDEBAR_FOR_CHANNEL",
  SET_POSTS_QUERY = "SET_POSTS_QUERY",
  REFRESH_SIDEBAR = "REFRESH_SIDEBAR",
  GOT_TO_SIDEBAR_CHANNEL = "GOT_TO_SIDEBAR_CHANNEL",
  SHOW_PUBLIC_GROUP_LOGIN = "SHOW_PUBLIC_GROUP_LOGIN",
  SHOW_PUBLIC_GROUP_SIGNUP = "SHOW_PUBLIC_GROUP_SIGNUP",
  MODAL_IS_OPEN = "MODAL_IS_OPEN",
  ERROR_FROM_SERVER = "ERROR_FROM_SERVER",
  SET_SELECTED_GROUP = "SET_SELECTED_GROUP",
  SET_GROUP_THEME = "SET_GROUP_THEME",
  SET_DISABLE_LOGOUT = "SET_DISABLE_LOGOUT",
  TOGGLE_USER_PROFILE_SLIDER ="TOGGLE_USER_PROFILE_SLIDER", 
  OPEN_USER_PROFILE_SLIDER = "OPEN_USER_PROFILE_SLIDER",
  CLOSE_USER_PROFILE_SLIDER = "CLOSE_USER_PROFILE_SLIDER",
  SET_SLIDEOUT_PROFILE_DATA ="SET_SLIDEOUT_PROFILE_DATA"
}
