import { Post } from "./Post";

export interface IPostList {
  [key: string]: any;
  current_page: number;
  data: Post[];
  first_page_url?: string;
  from?: number;
  last_page?: number;
  last_page_url?: string;
  next_page_url: string;
  path?: string;
  per_page: number;
  prev_page_url?: string;
  to?: number;
  total?: number;
}

export class PostsList implements IPostList {
  [key: string]: any;
  public current_page: number = 0;
  public data: Post[] = [];
  public first_page_url: string = "";
  public from: number = 0;
  public last_page: number = 0;
  public last_page_url: string = "";
  public next_page_url: string = "";
  public path: string = "";
  public per_page: number = 0;
  public prev_page_url: string = "";
  public to: number = 0;
  public total: number = 0;

  constructor(instanceData?: PostsList) {
    if (instanceData) {
      this.deserialize(instanceData);
    }
  }

  private deserialize(instanceData: PostsList) {
    const keys: string[] = Object.keys(this);
    for (const key of keys) {
      if (instanceData.hasOwnProperty(key)) {
        this[key] = instanceData[key];
      }
    }
  }

  public addLatestPosts(posts: Post[]) {
    posts.forEach((post: Post) => {
      this.data.unshift(post);
    });
  }

  public addLatestPost(post: Post) {
    this.data.unshift(post);
  }

  public addPost(post: Post) {
    this.data.push(post);
  }

  public addPosts(posts: Post[]) {
    this.data = [...this.data, ...posts];
  }
}
